.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MinesSwapper
{
  user-select: none;
}

.OuterFrame {
  border-width: 4px;
  border-color: #ffffff;
  border-style: outset;
  background: #dddddd;
  width: fit-content;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.InnerFrame {
  border-width: 4px;
  border-color: #ffffff;
  border-style: inset;
  background: #dddddd;
  width: fit-content;
  
  margin-left: auto;
  margin-right: auto;
}

.InfoFrame{
  display: flex;
  width: auto;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.DigitalBack {
  height:36px;
  background-color: black;
  margin: 5px;
  padding: 2px;
}

.DigitalInner {
  position:relative;
  top: 50%;
  transform: translate(0, -50%);
  height: fit-content;
  min-height: 30px;
  font-family: digital7;
  color: red;
  font-size: 40px;

}


.Face {
  border-width: 4px;
  border-color: #ffffff;
  border-style: outset;
  background: #dddddd;
  position:relative;
  width: 40px;
  height: 40px;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
}

.Face:active
{
  border-style: inset;
}

.Grid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(var(--col),35px);
  grid-template-rows: repeat(var(--row),35px);
}





.Block {
  
  border-width: 4px;
  border-color: #ffffff;
  border-style: outset;
  background: #dddddd;
}
.Block:active
{
  border-style: inset;
}

.Hold {
  
  border-width: 4px;
  border-color: #ffffff;
  border-style: outset;
  background: #dddddd;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.Open {
  
  border-width: 1px;
  border-color: #bbbbbb;
  border-style: solid;
  background: #eeeeee;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  
  font-family: ARCADECLASSIC;
}

.Count1 {
  color:blue;
}

.Count2 {
  color:green;
}

.Count3 {
  color:red;
}

.Count4 {
  color:#000080;
}

.Count5 {
  color:#800000;
}

.Count6 {
  color:#007280;
}

.Count7 {
  color:#080a09;
}

.Count8 {
  color:#7c7c7c;
}

.Dead {

  border-width: 1px;
  border-color: #555555;

  border-style: solid;
  background: red;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  font-family: ARCADECLASSIC;
}
